import { createStore } from 'vuex'

export default createStore({
  state: {
    nav:[{
        path:"/secondary2/overview",
        title:"全面数字化的电子发票概述",
        id:"0",
        child:[
            {
                parendId:"0",
                path:"/secondary/overview/index/introduce",
                tips:"（一）",
                title:"全面数字化的电子发票是什么？",
                id:"0.1"
            },
            {
                parendId:"0",
                path:"/secondary/overview/index/invoice",
                tips:"（二）",
                title:"全面数字化的电子发票开具平台是什么？",
                id:"0.2"
            },
            {
                parendId:"0",
                path:"/secondary/overview/index/invoiceChange",
                tips:"（三）",
                title:"全面数字化的电子发票七大变化",
                id:"0.3"
            },
            {
                parendId:"0",
                path:"/secondary/overview/index/invoicediff",
                tips:"（四）",
                title:"全面数字化的电子发票与现有的发票样式有什么区别？",
                id:"0.4"
            },
            {
                parendId:"0",
                path:"/secondary/overview/index/invoiceadvantage",
                tips:"（五）",
                title:"使用全面数字化的电子发票有什么优点？",
                id:"0.5"
            },
        ]
    },
    {
        path:"/secondary2/overview",
        title:"电子税务局注册登录",
        id:"1",
        child:[
            {
                parendId:"1",
                path:"/secondary/overview/index/usersingup",
                tips:"（一）",
                title:"用户注册",
                id:"1.1"
            },
            {
                parendId:"1",
                path:"/secondary/overview/index/enterprise",
                tips:"（二）",
                title:"企业登录",
                id:"1.2"
            },
            {
                parendId:"1",
                path:"/secondary/overview/index/proxylogin",
                tips:"（三）",
                title:"自然人账号及代理账号登录",
                id:"1.3"
            },
            {
                parendId:"1",
                path:"/secondary/overview/index/applogin",
                tips:"（四）",
                title:"深圳税务APP登录",
                id:"1.4"
            },
        ]
    },
    {
        path:"/secondary2/overview",
        title:"全面数字化的电子发票开票操作指引",
        id:"2",
        child:[
            {
                parendId:"2",
                path:"/secondary/overview/index/setting",
                tips:"（一）",
                title:"开票员身份配置流程",
                id:"2.1"
            },
            {
                parendId:"2",
                path:"/secondary/overview/index/maintenance",
                tips:"（二）",
                title:"项目信息维护",
                id:"2.2"
            },
            {
                parendId:"2",
                path:"/secondary/overview/index/customerMaintenance",
                tips:"（三）",
                title:"客户信息维护",
                id:"2.3"
            },
            {
                parendId:"2",
                path:"/secondary/overview/index/options",
                tips:"（四）",
                title:"附加信息维护操作",
                id:"2.4"
            },
            {
                parendId:"2",
                path:"/secondary/overview/index/bluecharacter",
                tips:"（五）",
                title:"蓝字发票开具",
                id:"2.5"
            },
            {
                parendId:"2",
                path:"/secondary/overview/index/redcharacter",
                tips:"（六）",
                title:"红字发票信息录入与处理",
                id:"2.6"
            },
            {
                parendId:"2",
                path:"/secondary/overview/index/apply",
                tips:"（七）",
                title:"发票额度调整申请",
                id:"2.7"
            },
        ]
    },
    {
        path:"/secondary2/overview",
        title:"全面数字化的电子发票受票操作指引",
        id:"3",
        child:[
            {
                parendId:"3",
                path:"/secondary/overview/index/numberAccount",
                tips:"（一）",
                title:"税务数字账户介绍",
                id:"3.1"
            },
            {
                parendId:"3",
                path:"/secondary/overview/index/deduction",
                tips:"（二）",
                title:"抵扣勾选",
                id:"3.2"
            },
            {
                parendId:"3",
                path:"/secondary/overview/index/nondeductible",
                tips:"（三）",
                title:"不抵扣勾选",
                id:"3.3"
            },
            {
                parendId:"3",
                path:"/secondary/overview/index/cancellation",
                tips:"（四）",
                title:"注销勾选",
                id:"3.4"
            },
            {
                parendId:"3",
                path:"/secondary/overview/index/overdue",
                tips:"（五）",
                title:"逾期抵扣申请",
                id:"3.5"
            },
            {
                parendId:"3",
                path:"/secondary/overview/index/statistics",
                tips:"（六）",
                title:"发票查询统计",
                id:"3.6"
            },
            {
                parendId:"3",
                path:"/secondary/overview/index/check",
                tips:"（七）",
                title:"发票查验",
                id:"3.7"
            },
            {
                parendId:"3",
                path:"/secondary/overview/index/identification",
                tips:"（八）",
                title:"发票入账标识",
                id:"3.8"
            },
            {
                parendId:"3",
                path:"/secondary/overview/index/confirm",
                tips:"（九）",
                title:"红字信息确认单",
                id:"3.9"
            },
        ]
    },
    {
        path:"/secondary2/overview",
        title:"新办纳税人套餐式操作指引",
        id:"4",
        child:[
            {
                parendId:"4",
                path:"/secondary/overview/index/taxsign",
                tips:"（一）",
                title:"用户注册",
                id:"4.1"
            },
            {
                parendId:"4",
                path:"/secondary/overview/index/taxlogin",
                tips:"（二）",
                title:"企业登录",
                id:"4.2"
            },
            {
                parendId:"4",
                path:"/secondary/overview/index/taxinvoice",
                tips:"（三）",
                title:"全面数字化的电子发票开票",
                id:"4.3"
            },
            {
                parendId:"4",
                path:"/secondary/overview/index/taxreceipt",
                tips:"（四）",
                title:"全面数字化的电子发票受票",
                id:"4.4"
            },
        ]
    },
    {
        path:"/secondary2/overview",
        title:"征纳互动服务",
        id:"5",
        child:[
            {
                parendId:"5",
                path:"/secondary/overview/index/service",
                tips:"（一）",
                title:"服务入口",
                id:"5.1"
            },
            {
                parendId:"5",
                path:"/secondary/overview/index/staff",
                tips:"（二）",
                title:"人工互动",
                id:"5.2"
            },
            {
                parendId:"5",
                path:"/secondary/overview/index/message",
                tips:"（三）",
                title:"留言互动",
                id:"5.3"
            },
            {
                parendId:"5",
                path:"/secondary/overview/index/appointment",
                tips:"（四）",
                title:"预约功能",
                id:"5.4"
            },
        ]
    },{
        path:"/secondary2/overview",
        title:"常见问题 30 问",
        id:"6",
        child:[
            {
                parendId:"6",
                path:"/secondary/overview/index/problem",
                tips:"（一）",
                title:"",
                id:"6.1"
            }
        ]
    }]
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
