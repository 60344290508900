<template>
  <transition enter-active-class="show" leave-active-class="hide">
    <router-view/>
  </transition>
</template>

<script>
import {ref,getCurrentInstance} from "vue";
export default{
  setup(props,content){

  }
}
</script>

<style lang="less">
@font-face {
    font-family: 'mst';
    src: url("@/assets/SourceHanSerifCN-VF.otf.woff2");
}
*{
    font-family: 'mst';
}
@keyframes show{
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
@keyframes hide {
  0%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}
.show{
  animation: show 1s linear;
}
.hide{
  animation: hide 1s linear;
}
*{
  padding: 0;
  margin: 0;
}
</style>
