import { createRouter, createWebHistory,createWebHashHistory  } from 'vue-router'

const routes = [
  {
    path: '/',
    name: '/firstPage',
    redirect:'/secondary/overview'
  },
  {
    path:'/secondary/overview',
    name:'/secondary/overview',
    component: () => import('@/views/index.vue')
  },
  {
    path:'/secondary2',
    name:'/secondary2',
    component: () => import('@/layouts/secondary.vue'),
    children:[
      {
        path:'/secondary2/overview',
        name:'/secondary2/overview',
        component: () => import('@/views/overview.vue')
      }
    ]
  },
  {
    path:'/secondary/overview/index',
    name:'/secondary/overview/index',
    component: () => import('@/layouts/pageContent.vue'),
    children:[
      {
        path:"/secondary/overview/index/introduce",
        name:"/secondary/overview/index/introduce",
        component:()=>import('@/views/introduce.vue'),
      },
      {
        path:"/secondary/overview/index/invoice",
        name:"/secondary/overview/index/invoice",
        component:()=>import('@/views/invoice.vue'),
      },
      {
        path:"/secondary/overview/index/invoiceChange",
        name:"/secondary/overview/index/invoiceChange",
        component:()=>import('@/views/invoiceChange.vue'),
      },
      {
        path:"/secondary/overview/index/invoicediff",
        name:"/secondary/overview/index/invoicediff",
        component:()=>import('@/views/invoicediff.vue'),
      },
      {
        path:"/secondary/overview/index/invoiceadvantage",
        name:"/secondary/overview/index/invoiceadvantage",
        component:()=>import('@/views/invoiceadvantage.vue'),
      },
      {
        path:"/secondary/overview/index/usersingup",
        name:"/secondary/overview/index/usersingup",
        component:()=>import('@/views/usersingup.vue'),
      },
      {
        path:"/secondary/overview/index/enterprise",
        name:"/secondary/overview/index/enterprise",
        component:()=>import('@/views/enterprise.vue'),
      },
      {
        path:"/secondary/overview/index/proxylogin",
        name:"/secondary/overview/index/proxylogin",
        component:()=>import('@/views/proxylogin.vue'),
      },
      {
        path:"/secondary/overview/index/applogin",
        name:"/secondary/overview/index/applogin",
        component:()=>import('@/views/applogin.vue'),
      },
      {
        path:"/secondary/overview/index/setting",
        name:"/secondary/overview/index/setting",
        component:()=>import('@/views/setting.vue'),
      },
      {
        path:"/secondary/overview/index/maintenance",
        name:"/secondary/overview/index/maintenance",
        component:()=>import('@/views/maintenance.vue'),
      },
      {
        path:"/secondary/overview/index/customerMaintenance",
        name:"/secondary/overview/index/customerMaintenance",
        component:()=>import('@/views/customerMaintenance.vue'),
      },
      {
        path:"/secondary/overview/index/options",
        name:"/secondary/overview/index/options",
        component:()=>import('@/views/options.vue'),
      },
      {
        path:"/secondary/overview/index/bluecharacter",
        name:"/secondary/overview/index/bluecharacter",
        component:()=>import('@/views/bluecharacter.vue'),
      },
      {
        path:"/secondary/overview/index/redcharacter",
        name:"/secondary/overview/index/redcharacter",
        component:()=>import('@/views/redcharacter.vue'),
      },
      {
        path:"/secondary/overview/index/apply",
        name:"/secondary/overview/index/apply",
        component:()=>import('@/views/apply.vue'),
      },
      {
        path:"/secondary/overview/index/numberAccount",
        name:"/secondary/overview/index/numberAccount",
        component:()=>import('@/views/numberAccount.vue'),
      },
      {
        path:"/secondary/overview/index/deduction",
        name:"/secondary/overview/index/deduction",
        component:()=>import('@/views/deduction.vue'),
      },
      {
        path:"/secondary/overview/index/nondeductible",
        name:"/secondary/overview/index/nondeductible",
        component:()=>import('@/views/nondeductible.vue'),
      },
      {
        path:"/secondary/overview/index/cancellation",
        name:"/secondary/overview/index/cancellation",
        component:()=>import('@/views/cancellation.vue'),
      },
      {
        path:"/secondary/overview/index/overdue",
        name:"/secondary/overview/index/overdue",
        component:()=>import('@/views/overdue.vue'),
      },
      {
        path:"/secondary/overview/index/statistics",
        name:"/secondary/overview/index/statistics",
        component:()=>import('@/views/statistics.vue'),
      },
      {
        path:"/secondary/overview/index/check",
        name:"/secondary/overview/index/check",
        component:()=>import('@/views/check.vue'),
      },
      {
        path:"/secondary/overview/index/identification",
        name:"/secondary/overview/index/identification",
        component:()=>import('@/views/identification.vue'),
      },
      {
        path:"/secondary/overview/index/confirm",
        name:"/secondary/overview/index/confirm",
        component:()=>import('@/views/confirm.vue'),
      },
      {
        path:"/secondary/overview/index/taxsign",
        name:"/secondary/overview/index/taxsign",
        component:()=>import('@/views/taxsign.vue'),
      },
      {
        path:"/secondary/overview/index/taxlogin",
        name:"/secondary/overview/index/taxlogin",
        component:()=>import('@/views/taxlogin.vue'),
      },
      {
        path:"/secondary/overview/index/taxinvoice",
        name:"/secondary/overview/index/taxinvoice",
        component:()=>import('@/views/taxinvoice.vue'),
      },
      {
        path:"/secondary/overview/index/taxreceipt",
        name:"/secondary/overview/index/taxreceipt",
        component:()=>import('@/views/taxreceipt.vue'),
      },
      {
        path:"/secondary/overview/index/service",
        name:"/secondary/overview/index/service",
        component:()=>import('@/views/service.vue'),
      },
      {
        path:"/secondary/overview/index/staff",
        name:"/secondary/overview/index/staff",
        component:()=>import('@/views/staff.vue'),
      },
      {
        path:"/secondary/overview/index/message",
        name:"/secondary/overview/index/message",
        component:()=>import('@/views/message.vue'),
      },
      {
        path:"/secondary/overview/index/appointment",
        name:"/secondary/overview/index/appointment",
        component:()=>import('@/views/appointment.vue'),
      },
      {
        path:"/secondary/overview/index/problem",
        name:"/secondary/overview/index/problem",
        component:()=>import('@/views/problem.vue'),
      }
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to,from,next)=>{
  if(to.matched.length==0){
    next({
      path:"/secondary/overview"
    });
  }else{
    next();
  }
})

export default router
