import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.css'
import "animate.css"

const app=createApp(App);
app.use(Vant);
app.directive('openImg',{
    mounted(el,value){
        el.addEventListener('click',()=>{
            window.open(el.src);
        })
    }
})

app.use(store).use(router).mount('#app')
